<template>
  <div class="script" align="center">
    <p class="title text-center">{{ $t('main_settings.title') }}</p>
    <v-card width="65%" v-if="Object.keys(settings).length === 4">
      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-title align="left">
              {{ $t('main_settings.object_name') }}
            </v-list-item-title>
            <v-col cols="3">
            
            <v-text-field
              v-model="settings.object_name.value"
              @change="saveSetting(settings.object_name)"
              dense
              hide-details
              :disabled="!(isOperator && isLicenseValid)"
            />
            </v-col>
          </v-list-item>
          <v-list-item>
            <v-list-item-title align="left">
              {{ $t('main_settings.object_address') }}
            </v-list-item-title>
            <v-col cols="3">
            
            <v-text-field
              v-model="settings.object_place.value"
              @change="saveSetting(settings.object_place)"
              dense
              hide-details
              :disabled="!(isOperator && isLicenseValid)"
            />
            </v-col>
          </v-list-item>
          <v-list-item>
            <v-list-item-title align="left">
              {{ $t('main_settings.work_mode') }}
            </v-list-item-title>
            <v-col cols="3">
              <v-select
                :items="modeValues"
                :item-text="item => $t(item.title)"
                item-value="value"
                v-model="settings.mode.value"
                @change="saveSetting(settings.mode)"
                dense
                hide-details
                :disabled="!(isOperator && isLicenseValid)"
              />
            </v-col>
          </v-list-item>
          <v-list-item>
            <v-list-item-title align="left">
              FPS
            </v-list-item-title>
            <v-col cols="3">
              <v-text-field
                v-model="settings.fps.value"
                @change="saveSetting(settings.fps)"
                dense
                hide-details
                :disabled="!(isOperator && isLicenseValid)"
              />
            </v-col>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "mainSettings",
  data: ()=>({
  }),
  computed: {
    settings() {
      return this.$store.getters['mainSettings/getSettings']
    },
    modeValues() {
      return this.$store.state.mainSettings.modeValues
    },
    isOperator() {
      return this.$store.getters.isOperator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
  },
  methods: {
    saveSetting (settingRecord) {
      this.$store.dispatch('mainSettings/saveSetting', settingRecord)
    },
  }
}
</script>

<style>
.dev_active {
  color: #aa0000;
}
</style>